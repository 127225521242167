<template>
  <div class="evaluate-progress">
    <EvaluateHeader :name="evaluation.name + ' / 进度跟踪'"> </EvaluateHeader>
    <div class="tab-box">
      <div class="search-box">
        <div>
          班级：
          <el-select
            v-model="searchItems.classNum"
            placeholder="请选择"
            clearable
            @change="handleClick"
          >
            <el-option
              v-for="item in classList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="el-progress-box">
          考评进度：
          <el-progress
            :percentage="progressAll"
            :stroke-width="6"
          ></el-progress>
        </div>
      </div>
      <el-table :data="progressTrackingList" border style="width: 100%">
        <el-table-column
          prop="teacherName"
          label="考评人"
          width="336"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.teacherRole | setName(teacherRole)
            }}<template v-if="scope.row.subjectId"
              >（{{ scope.row.subjectId | setName(getSubjectList) }}）</template
            >:
            {{ scope.row.teacherName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="classNum"
          label="班级"
          width="283"
          align="center"
        >
          <template slot-scope="scope">
            <!-- <template v-if="scope.row.teacherRole == 6">-</template> -->
            {{ scope.row.classNum }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="考评数量"
          width="283"
          align="center"
        >
          <template slot-scope="scope">
            <span :class="{ err: scope.row.totalMark < scope.row.total }">{{
              scope.row.totalMark
            }}</span>
            / {{ scope.row.total }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="进度" align="center">
          <template slot-scope="scope">
            <el-progress
              :percentage="scope.row.progress"
              :stroke-width="6"
            ></el-progress>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  evaluationGetbyid,
  progressTracking,
  trackingAll,
  // putEvaluationstudent,
  // studentsinfo,
  // deleteEvaluationstudent,
} from "@/core/api/evaluate/evaluate";
import { teacherRole } from "@/core/util/constdata";
import { getSubjectList } from "@/core/util/util";
import EvaluateHeader from "./component/evaluate-header";
export default {
  name: "EvaluateProgress",
  components: { EvaluateHeader },
  data() {
    return {
      getSubjectList: getSubjectList(),
      progressAll: 0,
      current: 1,
      size: 10,
      total: 2,
      searchItems: {
        classNum: "",
        // name: "",
      },
      teacherRole: teacherRole,
      progressTrackingList: [],
      classList: [],
      evaluation: {
        name: "",
      },
    };
  },
  created() {
    this.evaluationGetbyid();
    this.progressTracking();
  },
  methods: {
    // 获取单个考评详情
    evaluationGetbyid() {
      let data = { id: this.$route.query.id };
      evaluationGetbyid(data).then((res) => {
        this.evaluation = res.data.data;
        this.classList = res.data.data.classNums.split(",");
      });
    },
    // 获取进度列表
    progressTracking() {
      let data = {
        id: this.$route.query.id,
        current: this.current,
        size: this.size,
      };
      Object.assign(data, this.searchItems);
      this.trackingAll();
      progressTracking(data).then((res) => {
        this.total = res.data.data.total;
        res.data.data.records.map((index) => {
          if (index.progress) {
            index.progress = Number(index.progress.toFixed(2));
          }
        });
        this.progressTrackingList = res.data.data.records;
        this.listLoading = false;
        // this. = res.data.data;
        // this.classList = res.data.data.classNums.split(",");
      });
    },
    // 获取总进度列表
    trackingAll() {
      let data = {
        id: this.$route.query.id,
      };
      Object.assign(data, this.searchItems);
      trackingAll(data).then((res) => {
        this.progressAll = Number(res.data.data.toFixed(2));
      });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.size = val;
      this.progressTracking();
      // this.trackingAll();
    },
    handleCurrentChange(val) {
      this.current = val;
      // this.trackingAll();
      this.progressTracking();
      // console.log(`当前页: ${val}`);
    },
    handleClick() {
      this.current = 1;
      // this.trackingAll();
      this.progressTracking();
    },
  },
};
</script>
<style scoped lang="scss">
.evaluate-progress {
  ::v-deep .el-progress-bar {
    padding-right: 100px !important;
    margin-right: -100px;
  }
  .el-table {
    .el-progress {
      width: 380px;
      margin: 0 auto;
    }
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
  }
  .tab-box {
    background-color: #ffffff;
    padding: 24px;
    margin-top: 24px;
    .err {
      color: red;
    }
    .tab-msg {
      padding: 10px;
      background: #f2f8ff;
      border: 1px dashed #2474ed;
      color: #5f5f68;
      margin-bottom: 24px;
    }
  }
  .search-box {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    margin-bottom: 18px;
    .el-progress-box {
      display: flex;
      align-items: center;
    }
    .el-progress {
      width: 260px;
    }
    // padding: 18px 24px;
    .el-input {
      width: 140px;
    }
    .el-select {
      width: 140px;
      margin-right: 18px;
    }
  }
}
</style>
